
import { Component, Vue } from 'vue-property-decorator'
import { AuthModule } from '@/store/modules/AuthModule'
import UserService from './services/user-service'

@Component({
  name: 'Account'
})

export default class Account extends Vue {
    private user = {}

    created () {
      this.populateUserInfo()
    }

    private async populateUserInfo () {
      const response = await UserService.userInfo(AuthModule.user.id)
      this.user = response.data
    }
}
