import APIService from '@/services/api-service'
const resource = '/user'

class UserService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public userInfo (userId: any) {
    return this.connector.get(`${resource}/${userId}`)
  }
}

export default new UserService()
