
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
// import OfficeJob from './components/Jobs/OfficeJobs.vue'
  @Component({
    name: 'Dashboard'
  })
export default class Dashboard extends Vue {
  get dashboard () {
    if (AuthModule.permissions.includes('op@office')) {
      return () => import('./components/OfficeDashboard.vue')
    }

    if (AuthModule.permissions.includes('op@barge')) {
      return () => import('./components/BargeDashboard.vue')
    }

    if (AuthModule.permissions.includes('op@slpa')) {
      return () => import('./components/PortDashboard.vue')
    }
  }
}

